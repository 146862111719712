<template>
  <div class="content">
    <el-card shadow="never" class="new-card">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">角色管理</div>
        </div>
      </div>
      <div class="card-header-left">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="角色名">
            <el-input
              v-model="formInline.roleName"
              size="small"
              placeholder="请输入角色名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" size="small"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card shadow="never" class="new-card" style="margin-top: 24px">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">角色列表</div>
        </div>
        <div class="card-header-left">
          <el-button type="plain" size="small" @click="handleDelete()"
            >删除</el-button
          >
          <el-button type="primary" size="small" @click="handleEdit(0)"
            >创建角色</el-button
          >
        </div>
      </div>
      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table :data="tableData" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="ID" prop="id" align="left" />
          <el-table-column label="角色名称" prop="roleName" align="left" />
          <el-table-column label="角色描述" prop="roleDescribe" align="left" />
          <el-table-column label="更新时间" prop="updateTime" align="center" />
          <el-table-column label="操作人" prop="editorsName" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleEdit(scope.row.id)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div
          style="
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 30px;
          "
        >
          <el-pagination
            :current-page="page.current"
            :page-sizes="page.sizes"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      formInline: {
        roleName: "",
      },
      multipleSelection: [],
      tableData: [], // 表格数据
      page: {
        current: 1, // 当前在第几页
        sizes: [10, 20, 50, 100], // 每页显示多少条选择数组
        size: 10, // 每页显示多少条
        total: 0, // 共几条
      },
      tableHeight: "850px",
    };
  },
  created() {
    this.getUserList();
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 210 + "px";

    // 全选的数据
    this.oneDimensionalList = [];
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((item, index) => {
        this.multipleSelection.push(item.id);
      });
    },
    // 打开编辑
    handleEdit(items) {
      this.$router.push(`/auth_detail/${items}`);
    },
    // 删除角色
    handleDelete(id) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请先选择数据");
        return;
      }
      this.$confirm("您确定要删除角色么？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = [...new Set(this.multipleSelection)];
        let param = {
          idList: data,
        };
        // return
        this.axios.post("DELETE_USER", param).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getUserList();
          }
        });
      });
    },
    // 获取标签样式
    getTagType(item) {
      if (item % 2 == 0) {
        return "new-tag1";
      } else if (item % 2 == 1) {
        return "new-tag2";
      } else if (item % 2 == 2) {
        return "new-tag3";
      } else if (item % 2 == 3) {
        return "new-tag4";
      }
    },
    // 获取用户列表
    getUserList() {
      this.axios
        .get("NEW_ROLE_LIST", {
          current: this.page.current,
          size: this.page.size,
          roleName: this.formInline.roleName,
        })
        .then((result) => {
          console.log("用户列表", result);
          this.tableData = result.data.records;
          this.page.total = result.data.total;
        });
    },
    search() {
      this.getUserList();
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
      this.getUserList();
    },
    // 切换到第几页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getUserList();
    },
  },
};
</script>

<style>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 20px;
  /* background: #F0F2F5; */
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
